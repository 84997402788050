// MainComponent.js
import React, { useState } from 'react';
import ButtonComponent from './ButtonComponent';
import IframeComponent from './IframeComponent';

const MainComponent = () => {
    const [booleanValue, setBooleanValue] = useState(false);
    const [data, setData] = useState(null);

    const handleBooleanValue = (value) => {
        console.log("Received boolean value from ButtonComponent:", value);
        setBooleanValue(value);
    };

    const handleData = (value) => {
        console.log("Received data from IframeComponent:", value);
        setData(value);
    };

    return (
        <div>
            <ButtonComponent sendBooleanValue={handleBooleanValue} data={data} />
            <IframeComponent url="http://localhost:5173/" booleanValue={booleanValue} sendData={handleData} />
        </div>
    );
};

export default MainComponent;
