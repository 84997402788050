// App.js
import React from 'react';
import MainComponent from './tests/MainComponent';

const Testing = () => {
  return (
    <div>
      <h1>React Components Interaction</h1>
      <MainComponent />
    </div>
  );
};

export default Testing;
