// ButtonComponent.js
import React from 'react';

const ButtonComponent = ({ sendBooleanValue, data }) => {
    const handleClick = () => {
        console.log("Button clicked, sending true to MainComponent");
        sendBooleanValue(true);
    };

    return (
        <div>
            <button onClick={handleClick}>Click me</button>
            {data && <p>Data from IframeComponent: {JSON.stringify(data)}</p>}
        </div>
    );
};

export default ButtonComponent;
